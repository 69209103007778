import gsap from 'gsap';
import $ from '@vaersaagod/tools/Dom';
import Dispatch from '@vaersaagod/tools/Dispatch';
import Viewport from '@vaersaagod/tools/Viewport';
import * as Events from '../lib/events';
import shouldAnimate from "../lib/ReducedMotion";

const MODAL_BODY_CLASS = 'is-modal-open';

export default (el, props) => {
    const dom = $(el);
    const body = $('body');
    const submenuToggles = dom.find('[data-submenu-toggle]');

    let active = false;
    let trigger = null;

    const keyHandler = e => {
        const key = e.keyCode || e.which;
        if (active && key === 27) {
            Dispatch.emit(Events.MENU_CLOSE);
        }
    };

    const onOpen = () => {
        if (!active) {
            active = true;
            trigger = document.activeElement;
            document.addEventListener('keyup', keyHandler);
            gsap.set(el, { display: 'block', opacity: 0 });
            gsap.to(el, { duration: shouldAnimate() ? 0.35 : 0.05, opacity: 1, ease: 'power1.inOut' });
            body.addClass(MODAL_BODY_CLASS);
            Viewport.lockTabbing([el, $('.page-header__hamburger').get(0)]);
        }
    };

    const onClose = () => {
        if (active) {
            active = false;
            document.removeEventListener('keyup', keyHandler);
            body.removeClass(MODAL_BODY_CLASS);
            gsap.to(el, { duration: shouldAnimate() ? 0.2 : 0.05, opacity: 0, ease: 'power1.out', clearProps: 'all' });
            Viewport.releaseTabbing(trigger);
        }
    };

    const onToggleSubmenu = e => {
        const target = $(e.triggerTarget);
        const shouldExpand = target.attr('aria-expanded') === 'false';
        target.attr('aria-expanded', shouldExpand ? 'true' : 'false');
    };

    const init = () => {
        submenuToggles.on('click', onToggleSubmenu);
        Dispatch.on(Events.MENU_OPEN, onOpen);
        Dispatch.on(Events.MENU_CLOSE, onClose);
    };

    const destroy = () => {
        submenuToggles.off('click', onToggleSubmenu);
        Dispatch.off(Events.MENU_OPEN, onOpen);
        Dispatch.off(Events.MENU_CLOSE, onClose);
    };

    return {
        init,
        destroy
    };
};
